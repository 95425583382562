(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name components.controller:ToolbarCtrl
   *
   * @description
   *
   */
  angular
  .module('neo')
  .controller('ToolbarCtrl', ToolbarCtrl);

  function ToolbarCtrl(authManager, $state) {
    var vm = this;
    vm.ctrlName = 'ToolbarCtrl';
    vm.authManager = authManager;
    vm.$state = $state;

    vm.openMenu = function ($mdMenu, ev) {
      $mdMenu.open(ev);
    };
  }
}());
